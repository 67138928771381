import { adminurl, weburl, imgapi } from "@/utils/config";
import tools from "@/utils/public.js";
import store from "@/store";
const { toGet, toPost } = tools;
// sotre值
const shopId = store.getters.getShopId;
export default {
  // 服务者列表
  async staffList(data) {
    data = {
      ...data,
      shop_id: shopId,
    };
    return await toPost(adminurl + "user/show", data);
  },
  // 服务者状态
  async staffStatus(data) {
    return await toPost(adminurl + "user/freeze_user", data);
  },
  // 重置密码
  async resetStaffPwd(id) {
    return await toGet(adminurl + "user/reset_pwd/", id);
  },
};
