import { adminurl, weburl, imgapi } from "@/utils/config";
import tools from "@/utils/public.js";
const { toGet, toPost } = tools;
export default {
  // 线下商家列表
  async getShopList() {
    return await toGet(weburl + "shop/infos");
  },

  // 单商家详情
  async getShopInfos(id) {
    return await toGet(weburl + "shop/info/", id);
  },

  // 添加线下商家
  async addShopInfo(data) {
    return await toPost(weburl + "shop/add_info/", data);
  },

  // 修改商家信息
  async updateShopInfo(data) {
    return await toPost(weburl + "shop/modify_info/", data);
  },

  //    地图相关接口
  // 地图获取当前经纬度
  async getAddressByIp() {
    return await toPost(weburl + "role/map/get_address_by_ip");
  },
  // 根据经纬度解析地址
  async getAddressByLocation(map) {
    return await toGet(weburl + "role/map/get_address_by_location/", map);
  },
  // 搜索地址
  async getAddressByStr(data) {
    return await toPost(weburl + "role/map/get_address_by_str/", data);
  },
  // 搜索周边
  async getSearchByStr(str) {
    return await toGet(weburl + "role/map/get_search_by_str/", str);
  },

  // 平台配置
  async showAdminRatio() {
    return await toGet(adminurl + "system/show_ratio");
  },
};
